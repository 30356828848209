import React, { useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Search from './Search';
import { ArrowLeft } from 'lucide-react';
import SubscriberChange from './SubscriberChange';
import formatNumber from '../utils/format';


const AudienceDetail = () => {
  const { audience } = useParams(); // Audience title from the route
  const location = useLocation();
  const navigate = useNavigate(); // Navigate hook for back button functionality

  const subreddits = location.state?.subreddits || JSON.parse(localStorage.getItem(audience)) || [];
  const [searchResults, setSearchResults] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control side menu visibility
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const handleSearchResults = (results) => {
    // Sort results by `created_utc` in descending order (newest first)
    const sortedResults = results.sort((a, b) => b.created_utc - a.created_utc);
    setSearchResults(sortedResults);
    setIsMenuOpen(true); // Open the side menu when results are received
    analyzeSearchResults(sortedResults); // Call analyze here with search results
  };

  const analyzeSearchResults = async (searchResults) => {
    try {
      setIsAnalyzing(true);
      // Only send essential data
      const simplifiedResults = searchResults.map(post => ({
        title: post.title,
        selftext: post.selftext,
        score: post.score
      }));

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/analyze`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ searchResults: simplifiedResults }),
      });

      const data = await response.json();
      setAnalysisResult(data.analysis);
    } catch (error) {
      console.error('Error analyzing audience:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const getValidIconUrl = (subreddit) => {
    if (!subreddit) {
      return null;
    }
    return subreddit.icon_img;
  };

  return (
    <div className="pt-20 px-4 min-h-screen bg-[#1A2238]">
      <div className="min-h-screen bg-[#121212]">
        {/* Header */}
        <div className="border-b border-gray-800 p-4">
          <div className="max-w-7xl mx-auto">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-3">
                <button
                  onClick={() => navigate('/dashboard')}
                  className="text-white hover:text-indigo-300 transition-colors"
                >
                  <ArrowLeft size={20} />
                </button>
                <h1 className="text-xl font-bold text-white">{audience}</h1>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-7xl mx-auto p-6">
          {/* Search Bar */}
          <div className="mb-6">
            <Search subreddits={subreddits} onSearchResults={handleSearchResults} />
          </div>

          {/* Subreddits Section */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-white">Subreddits</h2>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {subreddits.map((subreddit, index) => (
                <div key={index} className="bg-[#1c1c1c] rounded-lg p-4">
                  <div className="flex items-center mb-3">
                    <img
                      src={getValidIconUrl(subreddit) || 'https://via.placeholder.com/40'}
                      alt={`${subreddit.subreddit_name_prefixed} icon`}
                      className="h-10 w-10 rounded-full mr-3"
                    />
                    <div>
                      <h3 className="text-white font-semibold">{subreddit.subreddit_name_prefixed}</h3>
                      <div className="flex items-center gap-2">
                        <span className="text-white">{formatNumber(subreddit.subscribers)}</span>
                        <SubscriberChange change={subreddit.subscribersChange} />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 flex justify-end">
                    <a
                      href={`https://www.reddit.com/${subreddit.subreddit_name_prefixed}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-indigo-400 hover:text-indigo-300 text-sm"
                    >
                      Visit Subreddit
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Side Menu with Overlay for Results */}
      {isMenuOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black bg-opacity-70 z-40"
            onClick={() => setIsMenuOpen(false)}
          ></div>

          {/* Results Menu */}
          <div className="fixed top-0 right-0 h-full w-full md:w-1/2 bg-[#1c1c1c] shadow-lg z-50 transition-transform transform flex flex-col">
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b border-gray-800">
              <h2 className="text-xl font-semibold text-white">Search Results</h2>
              <button
                onClick={() => setIsMenuOpen(false)}
                className="text-gray-400 hover:text-white"
              >
                <span className="text-2xl">×</span>
              </button>
            </div>

            {/* Search Results List - Takes remaining space */}
            <div className="flex-1 overflow-y-auto p-4">
              <ul className="space-y-4">
                {searchResults.map((result, index) => {
                  const postDate = new Date(result.created_utc * 1000);
                  const now = new Date();
                  let formattedDate = postDate.toDateString() === now.toDateString()
                    ? postDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                    : postDate.toLocaleDateString();

                  return (
                    <li
                      key={index}
                      className="relative bg-[#252525] p-4 rounded-lg border border-gray-800 hover:border-gray-700 transition-colors"
                    >
                      <a
                        href={`https://www.reddit.com${result.permalink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-lg font-bold text-white hover:text-indigo-400 mb-1 block"
                      >
                        {result.title}
                      </a>
                      <p className="text-sm text-gray-400 mb-2 line-clamp-2">
                        {result.selftext || 'No additional text available.'}
                      </p>
                      <div className="flex justify-between items-center mt-2">
                        <div className="text-sm text-indigo-400">
                          {result.subreddit_name_prefixed}
                        </div>
                        <div className="text-xs text-gray-500">
                          {formattedDate}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* AI Analysis Section - Sticky at bottom */}
            <div className="border-t border-gray-800 bg-[#151515] p-4">
              <h3 className="text-lg font-semibold mb-2 text-white">AI Analysis</h3>
              {isAnalyzing ? (
                <div className="flex items-center justify-center py-4">
                  <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-indigo-400"></div>
                  <span className="ml-2 text-gray-300">Analyzing posts...</span>
                </div>
              ) : analysisResult ? (
                <div className="text-sm text-gray-300">
                  <div className="whitespace-pre-wrap max-h-48 overflow-y-auto">
                    {analysisResult}
                  </div>
                </div>
              ) : (
                <div className="text-gray-500 text-center py-2">
                  Analyzing search results...
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AudienceDetail;
