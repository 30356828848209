import { useState } from 'react';
import PricingContent from '../shared/PricingContent';

const PricingModal = ({ onClose }) => {
    const [selectedPriceId, setSelectedPriceId] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handleSelectPlan = async () => {
        if (!selectedPlan?.priceId) {
            onClose();
            return;
        }
        
        if (selectedPlan.title === 'Free' || selectedPlan.price === 'Free') {
            console.log('Free tier selected, closing modal');
            onClose();
            return;
        }
        
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/create-checkout-session`, {      
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ priceId: selectedPlan.priceId }),
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            if (data.url) {
                window.location.href = data.url;
            } else {
                throw new Error('No session URL received');
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            alert('Failed to start checkout process. Please try again.');
        }
    };

    return (
        <div className="modal fixed inset-0 flex items-center justify-center bg-black/50 z-50">
            <div className="modal-content bg-white rounded-2xl max-w-2xl w-full">
                {/* Header */}
                <div className="p-6">
                    <div className="flex items-start justify-between">
                        <div className="flex gap-4">
                            <div className="w-12 h-12 bg-gray-100 rounded-xl flex items-center justify-center">
                                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                    <path d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </div>
                            <div>
                                <h2 className="text-2xl font-semibold text-gray-900">Select plan</h2>
                                <p className="text-gray-500 mt-1">Simple and flexible per-user pricing.</p>
                            </div>
                        </div>
                        <button 
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <path d="M18 6L6 18M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>

                {/* Content */}
                <div className="px-6 pb-6">
                    <PricingContent onPlanSelect={setSelectedPlan} selectedPlan={selectedPlan} />
                </div>

                {/* Footer */}
                <div className="p-6 bg-gray-50 rounded-b-2xl flex justify-center gap-3">
                    <button 
                        onClick={onClose}
                        className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={handleSelectPlan}
                        disabled={!selectedPlan}
                        className={`px-4 py-2 rounded-lg ${selectedPlan 
                            ? 'bg-[#8B5CF6] text-white hover:bg-[#7C3AED]' 
                            : 'bg-gray-200 text-gray-500 cursor-not-allowed'} transition-colors`}
                    >
                        Select plan
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PricingModal;