import { SignUpButton } from "@clerk/clerk-react";
import dashboardScreenshot from '../assets/dashboard_screenshot.png';

const HeroSection = () => {
    return (
        <section className="bg-[#121212] pt-32 pb-16 px-4 relative">
            {/* Grid Background Pattern */}
            <div 
                className="absolute inset-0 w-full h-full"
                style={{
                    backgroundImage: `
                        linear-gradient(to right, #1a1a1a 1px, transparent 1px),
                        linear-gradient(to bottom, #1a1a1a 1px, transparent 1px)
                    `,
                    backgroundSize: '40px 40px',
                    opacity: '0.2'
                }}
            />

            <div className="max-w-7xl mx-auto text-center relative z-10">
                <h1 className="text-white text-6xl font-bold mb-6 leading-tight">
                    Find your next big idea in Minutes
                    <br />
                    Not Days
                </h1>
                
                <p className="text-gray-400 text-xl mb-12 max-w-3xl mx-auto">
                Problem Spotter is a tool that will help you expedite your idea discovery in Reddit.
                </p>

                <SignUpButton mode="modal">
                    <button className="bg-[#7C3AED] text-white px-8 py-3 rounded-lg text-lg font-medium hover:bg-[#6D28D9] transition-colors">
                        Sign up
                    </button>
                </SignUpButton>

                <div className="mt-16">
                    <img 
                        src={dashboardScreenshot} 
                        alt="Dashboard Preview" 
                        className="rounded-xl shadow-2xl border border-gray-800 mx-auto"
                        style={{
                            maxWidth: '1200px',
                            width: '100%'
                        }}
                    />
                </div>
            </div>
        </section>
    );
};

export default HeroSection;