import React, { useState, useEffect } from 'react';
import { SignUpButton } from "@clerk/clerk-react";
import { pricingPlans } from '../data/pricing';
import PricingContent from './shared/PricingContent';

const buttonText = (plan) => 
    plan.title === 'Free Tier' ? 'Get Started' : 
    plan.title === 'Pro Tier' ? 'Start Free Trial' : 
    'Get Started';

const PricingSection = () => {
    return (
        <section id="pricing" className="bg-[#121212] py-20 px-4">
            <div className="max-w-7xl mx-auto">
                <div className="text-center mb-16">
                    <h2 className="text-gray-400 text-sm uppercase mb-4">Pricing</h2>
                    <h1 className="text-white text-5xl font-bold mb-6">
                        Plans that fit your scale
                    </h1>
                    <p className="text-gray-400 text-xl">
                        Simple, transparent pricing that grows with you. Try any plan free for 3 days.
                    </p>
                </div>

                <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
                    {pricingPlans.map((plan) => (
                        <div 
                            key={plan.priceId || `plan-${plan.title}`}
                            className="bg-[#1c1c1c] rounded-2xl p-8 border border-gray-800 relative"
                        >
                            {plan.popular && (
                                <div className="absolute -top-3 right-6 bg-[#7C3AED] text-white px-4 py-1 rounded-full text-sm">
                                    Popular
                                </div>
                            )}
                            
                            <h3 className="text-gray-400 mb-4">{plan.name}</h3>
                            <div className="mb-8 flex items-baseline gap-2">
                                <span className="text-white text-6xl font-bold">
                                    {plan.price === 0 ? 'Free' : `${plan.price}`}
                                </span>
                                {plan.price !== 0 && (
                                    <span className="text-gray-400">per month</span>
                                )}
                            </div>
                            
                            {plan.description && (
                                <p className="text-gray-400 mb-8">{plan.description}</p>
                            )}
                            
                            <SignUpButton mode="modal">
                                <button className="w-full bg-[#7C3AED] text-white px-6 py-4 rounded-lg mb-8 hover:bg-[#6D28D9] transition-colors text-lg font-medium">
                                    {buttonText(plan)}
                                </button>
                            </SignUpButton>

                            <div className="space-y-4">
                                <h4 className="text-gray-400 font-medium mb-4">FEATURES</h4>
                                {plan.features.map((feature, index) => (
                                    <div key={index} className="flex items-start gap-3">
                                        <span className="text-[#7C3AED] mt-1">✓</span>
                                        <span className="text-gray-400">{feature}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PricingSection;
