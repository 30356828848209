import { features } from '../data/features';

const FeatureCard = ({ icon, title, description }) => (
    <div className="flex flex-col items-center text-center mb-16">
        <div className="bg-[#1c1c1c] p-4 rounded-2xl mb-6">
            {icon}
        </div>
        <h3 className="text-white text-xl font-semibold mb-4">{title}</h3>
        <p className="text-gray-400 max-w-md">{description}</p>
    </div>
);

const FeaturesSection = () => {
    return (
        <section id="features" className="bg-[#121212] py-20 px-4">
            <div className="max-w-7xl mx-auto">
                <div className="text-center mb-16">
                    <h2 className="text-gray-400 text-sm uppercase mb-4">Features</h2>
                    <h1 className="text-white text-5xl font-bold mb-6">
                        Discovery that feels like it's from the future
                    </h1>
                    <p className="text-gray-400 max-w-3xl mx-auto text-lg">
                        Unlock the next generation of product discovery with AI-powered insights from Reddit. 
                        Instantly uncover trends, feature requests, and user needs—no digging required.
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <FeatureCard
                            key={index}
                            icon={
                                <svg 
                                    className="w-6 h-6 text-white" 
                                    fill="none" 
                                    viewBox="0 0 24 24" 
                                    stroke="currentColor"
                                >
                                    {/* You'll need to replace these paths with your actual icons */}
                                    <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth={2} 
                                        d={feature.iconPath} 
                                    />
                                </svg>
                            }
                            title={feature.title}
                            description={feature.description}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FeaturesSection;