import { Mail } from "lucide-react";
import { Link } from "react-router-dom"; // Import Link if you are using React Router

const Footer = () => {
    return (
        <footer className="w-full bg-[#121212] px-4 py-6 border-t border-gray-800">
            <div className="max-w-7xl mx-auto flex justify-between items-center">
                {/* Copyright text */}
                <div className="text-gray-400 text-sm">
                    © 2025 Problem Spotter. All rights reserved.
                </div>

                {/* Footer Links */}
                <div className="flex gap-6">
                    <button className="text-gray-400 hover:text-white text-sm transition-colors">
                        Contact
                    </button>
                    <button className="text-gray-400 hover:text-white text-sm transition-colors">
                        Terms
                    </button>
                    <button className="text-gray-400 hover:text-white text-sm transition-colors">
                        Privacy
                    </button>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

