import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Spinner from './Spinner';
import formatNumber from '../utils/format';
import { useUser } from "@clerk/clerk-react";
import PricingModal from "./modals/PricingModal";
import AudienceCard from './AudienceCard';
import { checkSubscriptionStatus } from '../utils/subscription';

const Dashboard = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const isNewSignUp = searchParams.get('signedUp') === 'true';
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [audienceMetrics, setAudienceMetrics] = useState({}); // Store metrics from backend

  const initialAudienceData = {
    'Startup Founders': [],
    'Fitness': [],
    'Pet Lovers': [],
    'Software Developers': [],

    'DevOps': [],
    'Crypto': [],
    'Marketers': [],
    'Stock Investors': [],
    'Generative AI': [],
    'Advertisers': [],

    'Product Managers': [],
    'Freelancers': [],
    'Video Editors': [],
    'Designers': [],
    'Data Scientists': [],
    'Fitness Enthusiasts': [],
    'Gaming': [],
    'Influencers': [],
    'AirBnb Hosts': [],
    'Remote Workers': [],
    'Productivity': [],
    'Cold Email': [],
    'Self-Promoters': [],
    'SEOs': [],
    'Photographers': [],

    'Ecommerce': [],
    'Parents': [],
    'No-Code': [],
    'Financial Independence': [],
    '3D Printers': [],
    'Copywriters': [],
    'Notion Users': [],
    'English Learners': [],
    'Saas Founders': [],
    'B2B Sales': [],
    'Newsletter Creators': [],
  };

  const [audienceData, setAudienceData] = useState(initialAudienceData);

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const loadFromLocalStorage = (key) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  };

  const clearCache = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('audience') || key.includes('subreddit')) {
        localStorage.removeItem(key);
      }
    });
    sessionStorage.removeItem("visited");
  };
  
  const decodeUrl = (url) => {
    if (!url) return null;

    // Decode HTML entities (e.g., '&amp;' -> '&')
    const textarea = document.createElement('textarea');
    textarea.innerHTML = url;
    return textarea.value;
  };

  const getValidIconUrl = (subreddit) => {
    if (!subreddit) {
      return null;
    }
    return subreddit.icon_img;
  };

  // Calculate audience metrics using backend API
  const calculateAudienceMetrics = async () => {
    try {
      // Only calculate metrics if we have audience data
      if (Object.values(audienceData).some(arr => arr.length > 0)) {
        const response = await fetch(`${baseUrl}/v1/audience/metrics/batch`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ audienceData }),
        });
        
        if (!response.ok) {
          throw new Error('Failed to calculate audience metrics');
        }
        
        const data = await response.json();
        setAudienceMetrics(data.results || {});
      }
    } catch (error) {
      console.error('Error calculating audience metrics:', error);
    }
  };

  const searchRedditByAudience = async (audience) => {
    const cachedData = loadFromLocalStorage(audience);

    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${baseUrl}/v1/search-subreddits?q=${audience}&limit=10`);
      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }

      let data = await response.json();

      // Remove duplicates based on `subreddit_name_prefixed`
      const uniqueSubreddits = new Map();
      data.forEach((subreddit) => {
        if (!uniqueSubreddits.has(subreddit.subreddit_name_prefixed)) {
          uniqueSubreddits.set(subreddit.subreddit_name_prefixed, subreddit);
        }
      });
      data = Array.from(uniqueSubreddits.values());

      setAudienceData((prevData) => ({
        ...prevData,
        [audience]: data,
      }));

      saveToLocalStorage(audience, data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkProStatus = async () => {
      try {
        const status = await checkSubscriptionStatus();
        setIsSubscribed(status);
      } catch (error) {
        console.error('Error checking subscription status:', error);
        // Fallback to development mode override if needed
        if (process.env.NODE_ENV === 'development') {
          setIsSubscribed(true);
        }
      }
    };
    
    checkProStatus();
    
    // Also update when URL contains payment=success
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('payment') === 'success') {
      setIsSubscribed(true);
      // Clear URL parameters
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (user && isNewSignUp) {
      //Comment out the line below when you need to do testing. 
      localStorage.setItem(`welcomeModalShown_${user.id}`, 'true');
      setShowPricingModal(true);
    }
  }, [isNewSignUp, user]);

  useEffect(() => {
    const audiences = Object.keys(audienceData);
    audiences.forEach((audience) => {
      searchRedditByAudience(audience);
    });
  }, []);

  // Recalculate metrics when audience data changes
  useEffect(() => {
    calculateAudienceMetrics();
  }, [audienceData]);

  const handleSelectPlan = async (priceId) => {
    console.log('handleSelectPlan called with:', priceId);
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const requestUrl = `${baseUrl}/api/v1/create-checkout-session`;
      console.log('Making request to:', requestUrl);

      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ 
          priceId,
          userId: user.id
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No URL in response');
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      alert('Failed to start checkout process. Please try again.');
    }
  };

  return (
    <div className="pt-20 px-4 min-h-screen bg-[#1A2238]">
      <div className="min-h-screen bg-[#121212]">
        {/* Header */}
        <div className="border-b border-gray-800 p-4">
          <div className="max-w-7xl mx-auto">
            <div className="flex justify-between items-center">
              <h1 className="text-xl font-bold text-white">Dashboard</h1>
              {!isSubscribed ? (
                <button
                  onClick={() => setShowPricingModal(true)}
                  className="bg-indigo-500 text-white px-4 py-2 rounded-lg hover:bg-indigo-600 transition"
                >
                  Upgrade to Pro
                </button>
              ) : (
                <div
                  className="bg-indigo-500 text-white px-4 py-2 rounded-lg font-medium"
                >
                  Pro
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-7xl mx-auto p-6">
          {loading && <Spinner />}

          {/* Audiences Section */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold text-white">Audiences</h2>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {Object.entries(audienceData).map(([audience, subreddits]) => {
                // Get metrics from backend API or use defaults
                const metrics = audienceMetrics[audience] || { 
                  totalAudience: 0, 
                  growthPercentage: 0, 
                  isIncrease: true 
                };
                
                return (
                  <Link 
                    to={`/audience/${audience}`} 
                    state={{ subreddits }} 
                    key={audience}
                    className="hover:opacity-90 transition-opacity"
                  >
                    <AudienceCard 
                      title={audience}
                      value={formatNumber(metrics.totalAudience)}
                      growth={`${metrics.isIncrease ? '+' : '-'}${Math.abs(metrics.growthPercentage)}%`}
                      period="vs last month"
                      subreddits={subreddits}
                      isGrowthPositive={metrics.isIncrease}
                    />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>

        {/* Pricing Modal */}
        {showPricingModal && (
          <PricingModal 
            onClose={() => setShowPricingModal(false)}
            handleSelectPlan={handleSelectPlan}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
