import { useState } from 'react';

const FAQItem = ({ question, answer, isOpen, onClick }) => {
    return (
        <div className="border-b border-gray-800">
            <button
                className="w-full py-6 flex justify-between items-center text-left"
                onClick={onClick}
            >
                <h3 className="text-white text-xl">{question}</h3>
                <span className="text-gray-400 text-2xl">
                    {isOpen ? '−' : '+'}
                </span>
            </button>
            
            {isOpen && (
                <div className="pb-6">
                    <p className="text-gray-400">{answer}</p>
                </div>
            )}
        </div>
    );
};

const FAQsSection = () => {
    const [openIndex, setOpenIndex] = useState(0);

    const faqs = [
        {
            question: "Is there a free trial available?",
            answer: "Yes, you can try us for free for 3 days."
        },
        {
            question: "Can I change my plan later?",
            answer: "Yes, you can upgrade or downgrade your plan at any time."
        },
        {
            question: "What is your cancellation policy?",
            answer: "You can cancel your subscription at any time, and you won't be charged for the next billing cycle."
        },
        {
            question: "Can other info be added to an invoice?",
            answer: "Yes, you can add additional information to your invoices, such as your business details or PO numbers."
        },
        {
            question: "How does billing work?",
            answer: "We bill monthly or annually, depending on your preference. Payment is charged at the start of each period."
        },
        {
            question: "How do I change my account email?",
            answer: "You can change your account email in your account settings. We'll send a verification email to confirm the change."
        }
    ];

    return (
        <section className="bg-[#121212] py-20 px-4">
            <div className="max-w-3xl mx-auto">
                <div className="text-center mb-16">
                    <h2 className="text-white text-4xl font-bold mb-4">
                        Frequently asked questions
                    </h2>
                    <p className="text-gray-400 text-xl">
                        Everything you need to know about the product and billing.
                    </p>
                </div>

                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <FAQItem
                            key={index}
                            question={faq.question}
                            answer={faq.answer}
                            isOpen={index === openIndex}
                            onClick={() => setOpenIndex(index === openIndex ? -1 : index)}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQsSection;
