// Cache object to store the price indefinitely
let priceCache = null;

// Check if we're in production mode
const isProduction = process.env.NODE_ENV === 'production';
console.log('Environment mode:', isProduction ? 'PRODUCTION' : 'DEVELOPMENT');
console.log('Using price ID:', process.env.REACT_APP_STRIPE_PRICE_ID);

export const pricingPlans = [
    {
        title: 'Free',
        price: 'Free',
        priceId: '0',
        features: [
            '100 searches per month',
            'Subreddit trends so that you can find the 💡 that you\'re looking for',
        ],
        isPopular: false,
    },
    
    {
        title: 'Pro',
        price: 'Loading...', // Default while fetching
        // Use the environment variable for the Pro subscription price ID
        priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
        features: [
            'Unlimited searches per month',
            'Subreddit trends so that you can find the 💡 that you\'re looking for',
            'AI suggestions',
        ],
        isPopular: true,
    }
];

// Initialize price fetching immediately
const initializePriceCache = async () => {
    try {
        console.log('Fetching prices from:', `${process.env.REACT_APP_API_BASE_URL}/api/v1/get-pricing`);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/get-pricing`);
        const prices = await response.json();
        
        // First try to find the Pro price specifically by ID from environment variable
        let proPrice = prices.find(price => 
            price.id === process.env.REACT_APP_STRIPE_PRICE_ID
        );
        
        // If the price ID from environment variable isn't found among active prices
        if (!proPrice) {
            console.warn(`Price ID ${process.env.REACT_APP_STRIPE_PRICE_ID} from environment variable is not active or doesn't exist`);
            
            // Find another active Pro price by product name as fallback
            proPrice = prices.find(price => 
                price.product.name.toLowerCase().includes('pro')
            );
            
            if (proPrice) {
                
                // Update the price ID in the pricing plan to use this active price
                const proTierPlan = pricingPlans.find(plan => plan.title === 'Pro');
                if (proTierPlan) {
                    proTierPlan.priceId = proPrice.id;
                }
            } else {
                console.error('No active Pro price found in Stripe data');
                throw new Error('No active Pro price found in Stripe data');
            }
        }
        
        // Set the price cache with the active price
        priceCache = `$${(proPrice.unit_amount / 100).toFixed(2)}`;
        
        // Update the Pro Tier price in pricingPlans
        const proTierPlan = pricingPlans.find(plan => plan.title === 'Pro');
        if (proTierPlan) {
            proTierPlan.price = priceCache;
            console.log('Updated Pro price to:', priceCache);
        }
        
        return priceCache;
    } catch (error) {
        console.error('Error initializing price cache:', error);
        throw error;
    }
};

// Start fetching the price immediately when this module is imported
initializePriceCache().catch(error => {
    console.error('Failed to initialize price cache:', error);
});

export const fetchProPrice = async () => {
    // If we have a cached price, return it
    if (priceCache) {
        return priceCache;
    }

    // If no cache exists yet, fetch price
    return initializePriceCache();
};
