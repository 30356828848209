export const checkSubscriptionStatus = async () => {
  try {

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/subscription-status`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors'
    });
        
    if (!response.ok) {
      throw new Error('Failed to fetch subscription status');
    }
    
    const data = await response.json();
    return data.isActive; // true if subscription is active
  } catch (error) {
    console.error('Error checking subscription status:', error);
    
    // For development: return true to show Pro badge anyway
    if (process.env.NODE_ENV === 'development') {
      console.log('DEV MODE: Returning true for Pro status');
      return true;
    }
    return false;
  }
}; 