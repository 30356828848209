import { title } from "process";

export const features = [
    {
        title: "Discover Trending Features",
        description: "Stay ahead of the curve by uncovering the latest ideas and product trends from Reddit's most engaged communities. Spot trends before your competitors do.",
        iconPath: "M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
    },
    {
        title: "AI Powered Insights",
        description: "Every search you perform comes with an in-depth AI-driven analysis, summarizing key trends, user sentiment, and feature demand. No more sifting through endless Reddit threads—our AI highlights the most valuable insights, helping you make data-backed decisions faster.",
        iconPath: "M13 10V3L4 14h7v7l9-11h-7z"
    },
    {
        title: "Data-Driven Insights",
        description: "Leverage real-time Reddit data to validate product ideas, track feature demand, and make informed decisions backed by actual user discussions.",
        iconPath: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
    },
    {
        title: "Track Product Mentions and Sentiment",
        description: "Monitor how users talk about your product (or competitors') across Reddit. Identify praise, complaints, and feature requests at scale.",
        iconPath: "M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
    },
    {
        title: "Spot Emerging Audience Segments",
        description: "Uncover new potential user groups by analyzing which communities are discussing your industry, product category, or competitors the most.",
        iconPath: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
    },
    {
        title: "Simplified Research, Instant Value",
        description: "No more endless scrolling—get key insights distilled from thousands of Reddit posts so you can focus on building what users actually want.",
        iconPath: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
    }
];