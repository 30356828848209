import { useState, useEffect } from 'react';
import logoUrl from '../assets/logo.png';
import { Menu } from 'lucide-react';
import { SignInButton, SignOutButton, UserButton, useUser, SignedOut, SignUpButton } from "@clerk/clerk-react";
import { useLocation, Link } from 'react-router-dom';
import PricingModal from './modals/PricingModal';
import MobileMenu from './MobileMenu';
import { checkSubscriptionStatus } from '../utils/subscription';

const NavBar = () => {
  const { isSignedIn } = useUser();
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [isProSubscriber, setIsProSubscriber] = useState(false);

  const isDashboard = location.pathname === '/dashboard';

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  // Check subscription status when signed in
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (isSignedIn) {
        try {
          console.log('Fetching subscription status from:', `${process.env.REACT_APP_API_BASE_URL}/v1/subscription-status`);
          const status = await checkSubscriptionStatus();
          setIsProSubscriber(status);
        } catch (error) {
          console.error('Error checking subscription status:', error);
          // For development: show the Pro badge anyway when there's an error
          if (process.env.NODE_ENV === 'development') {
            console.log('DEV MODE: Setting Pro status to true despite error');
            setIsProSubscriber(true);
          }
        }
      }
    };

    fetchSubscriptionStatus();

    // Also check when URL contains payment=success
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('payment') === 'success') {
      setIsProSubscriber(true);
      // Clear the URL parameters
      window.history.replaceState({}, document.title, location.pathname);
    }
  }, [isSignedIn, location.pathname, location.search]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="fixed w-full px-4 top-0 z-50">
      <nav className="bg-[#121212] rounded-2xl max-w-7xl mx-auto border border-gray-800">
        <div className="px-4 h-16 flex items-center justify-between">
          {/* Logo Name */}
          <Link to="/" className="text-white text-xl font-medium">
            Problem Spotter
            {isProSubscriber && isSignedIn && (
              <span className="ml-2 bg-indigo-600 text-white text-xs font-bold px-2 py-1 rounded">PRO</span>
            )}
          </Link>

          {/* Navigation Links */}
          <div className="hidden md:flex items-center gap-8">
            {!isSignedIn && (
              <>
                <button 
                  onClick={() => scrollToSection('features')} 
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  Features
                </button>
                <button 
                  onClick={() => scrollToSection('pricing')} 
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  Pricing
                </button>
              </>
            )}
          </div>

          {/* Auth Buttons */}
          <div className="hidden md:flex items-center gap-3">
            {!isSignedIn && (
              <>
                <SignInButton mode="modal">
                  <button className="bg-[#1A1A1A] text-white text-sm px-4 py-2 rounded-lg hover:bg-[#252525] transition-colors">
                    Log in
                  </button>
                </SignInButton>
                
                <SignUpButton mode="modal">
                  <button className="bg-[#7C3AED] text-white text-sm px-4 py-2 rounded-lg font-medium hover:bg-[#6D28D9] transition-colors">
                    Sign up
                  </button>
                </SignUpButton>
              </>
            )}

            {isSignedIn && (
              <UserButton afterSignOutUrl="/" />
            )}
          </div>

          {/* Mobile Menu */}
          <MobileMenu />
        </div>
      </nav>

      {/* Pricing Modal */}
      {showPricingModal && (
        <PricingModal 
          onClose={() => setShowPricingModal(false)} 
        />
      )}
    </div>
  );
};

export default NavBar;
