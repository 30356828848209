import { useState } from "react";
import { Menu, X } from "lucide-react";
import { SignInButton, SignUpButton, useUser } from '@clerk/clerk-react';

const MobileMenu = () => {
    const [navOpen, setNavOpen] = useState(false);
    const { isSignedIn } = useUser();

    return (
        <div className='block md:hidden relative'>
            {!navOpen ? (
                <button 
                    onClick={() => setNavOpen(true)}
                    className="text-white"
                >
                    <Menu size={32} />
                </button>
            ) : (
                <>
                    <button 
                        onClick={() => setNavOpen(false)}
                        className="text-white"
                    >
                        <X size={32} />
                    </button>
                    <div className="absolute right-0 w-48 top-12 
                        bg-[#121212] border border-gray-800 rounded-lg shadow-lg">
                        <ul className="flex flex-col py-2">
                            {!isSignedIn ? (
                                <>
                                    <li>
                                        <a
                                            href="#features" 
                                            className="block text-gray-300 hover:text-white px-4 py-2"
                                            onClick={() => setNavOpen(false)}
                                        >
                                            Features
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#pricing" 
                                            className="block text-gray-300 hover:text-white px-4 py-2"
                                            onClick={() => setNavOpen(false)}
                                        >
                                            Pricing
                                        </a>
                                    </li>
                                    <li className="px-4 py-2">
                                        <SignInButton mode="modal">
                                            <button className="w-full bg-white text-black px-4 py-2 rounded-lg hover:bg-gray-100">
                                                Login
                                            </button>
                                        </SignInButton>
                                    </li>
                                    <li className="px-4 py-2">
                                        <SignUpButton mode="modal">
                                            <button className="w-full bg-[#8B5CF6] text-white px-4 py-2 rounded-lg hover:bg-[#7C3AED]">
                                                Sign Up
                                            </button>
                                        </SignUpButton>
                                    </li>
                                </>
                            ) : (
                                <li className="px-4 py-2">
                                </li>
                            )}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

export default MobileMenu;
