import wildcraftedLogo from '../assets/wildcrafted-logo.png';
import amelieProfile from '../assets/amelie-laurent.png';

const CustomerReviewSection = () => {
    return (
        <section className="bg-[#121212] py-32 px-4">
            <div className="max-w-4xl mx-auto text-center">
                {/* Company Logo */}
                <img 
                    src={wildcraftedLogo} 
                    alt="Wildcrafted" 
                    className="h-8 mx-auto mb-16"
                />
                
                {/* Testimonial Quote */}
                <h2 className="text-white text-4xl md:text-5xl font-bold leading-tight mb-16">
                    We've been using Problem Spotter to kick start every new project and can't imagine working without it.
                </h2>
                
                {/* Customer Info */}
                <div className="flex flex-col items-center">
                    <img 
                        src={amelieProfile} 
                        alt="Customer" 
                        className="w-16 h-16 rounded-full mb-4"
                    />
                    <div className="text-white font-medium text-lg mb-2">
                        Amélie Laurent
                    </div>
                    <div className="text-gray-400">
                        Product Manager, Wildcrafted
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CustomerReviewSection;
