import { useNavigate } from 'react-router-dom';
import notFoundImage from '../assets/dashboard_screenshot.png';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen bg-[#121212] flex items-center justify-between p-16">
            <div className="max-w-2xl">
                <h2 className="text-gray-500 mb-4">404 error</h2>
                
                <h1 className="text-white text-6xl font-bold mb-6">
                    You've wandered off the map!
                </h1>
                
                <p className="text-gray-400 text-xl mb-8">
                    Looks like this page got lost in cyberspace. Let's get you back on track—try the homepage!
                </p>
                
                <div className="flex gap-4">
                    <button 
                        onClick={() => navigate(-1)}
                        className="flex items-center px-4 py-2 rounded-lg bg-[#1c1c1c] text-white hover:bg-[#2c2c2c] transition-colors"
                    >
                        <svg 
                            className="w-5 h-5 mr-2" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M10 19l-7-7m0 0l7-7m-7 7h18" 
                            />
                        </svg>
                        Go back
                    </button>
                    
                    <button 
                        onClick={() => navigate('/')}
                        className="px-4 py-2 rounded-lg bg-indigo-500 text-white hover:bg-indigo-600 transition-colors"
                    >
                        Go home
                    </button>
                </div>
            </div>

            <div className="w-[800px] relative">
                <div className="absolute inset-0 bg-gradient-to-r from-[#121212] to-transparent z-10 left-[-100px]" />
                <img 
                    src={notFoundImage} 
                    alt="Dashboard preview" 
                    className="rounded-xl shadow-2xl"
                />
            </div>
        </div>
    );
};

export default NotFound;

