import { useState, useEffect } from 'react';
import { pricingPlans, fetchProPrice } from '../../data/pricing';
import { SignUpButton, useUser } from '@clerk/clerk-react';

const PricingContent = ({ onPlanSelect, selectedPlan, showButtons = false }) => {
    const { isSignedIn } = useUser();
    const [prices, setPrices] = useState(pricingPlans);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadPrices = async () => {
            try {
                const proPrice = await fetchProPrice();
                setPrices(currentPrices => 
                    currentPrices.map(plan => 
                        plan.title === 'Pro' 
                            ? { ...plan, price: proPrice }
                            : plan
                    )
                );
            } catch (error) {
                console.error('Error loading prices:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        loadPrices();
    }, []);

    if (loading) return <div>Loading pricing plans...</div>;
    if (error) return <div>Error loading pricing: {error}</div>;

    return (
        <div className="grid grid-cols-2 gap-6 max-w-3xl mx-auto">
            {prices.map((plan) => (
                <div 
                    key={plan.priceId || plan.title}
                    className={`border p-6 rounded-lg ${!showButtons ? 'cursor-pointer' : ''} transition-all ${
                        selectedPlan?.title === plan.title 
                            ? 'border-2 border-indigo-500 ring-2 ring-indigo-200 bg-indigo-50' 
                            : plan.isPopular && !selectedPlan
                                ? 'border-2 border-indigo-500' 
                                : 'border-gray-200 hover:border-gray-300'
                    }`}
                    onClick={() => !showButtons && onPlanSelect && onPlanSelect(plan)}
                >
                    {selectedPlan?.title === plan.title && !showButtons && (
                        <div className="absolute top-2 right-2 w-6 h-6 bg-indigo-500 rounded-full flex items-center justify-center">
                            <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                    )}
                    <h3 className="text-2xl font-bold mb-2">{showButtons ? plan.title : ""}</h3>
                    <div className="text-3xl font-bold mb-2">
                        {plan.price} {plan.price !== 'Free' && <span className="text-gray-500 text-lg">/mo</span>}
                    </div>
                    
                    {showButtons && (
                        <button
                            onClick={() => onPlanSelect && onPlanSelect(plan)}
                            className="w-full bg-[#7C3AED] text-white px-4 py-2 rounded-lg hover:bg-[#6D28D9] transition-colors"
                        >
                            {plan.title === 'Free' ? 'Get Started' : 'Start Free Trial'}
                        </button>
                    )}
                    
                    <ul className="space-y-3 mt-4">
                        {plan.features.map((feature, index) => (
                            <li key={index} className="flex items-center text-sm">
                                <span className="text-indigo-500 mr-2">✓</span>
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default PricingContent; 